// eslint-disable-next-line  no-unused-vars
import BfMenu from '../BfMenu/BfMenu';

const template = document.createElement('template');
template.innerHTML = `
    <style>
        :host {
            --bf-wc-navigation-height: 73px;
            --bf-wc-navigation-text-color: #354A51;
            --bf-wc-menu-text-color: var(--bf-wc-navigation-text-color);

            font-size: 1rem;
            color: var(--bf-wc-navigation-text-color);
        }

        :host([hidden]) {
            display: none;
        }

        bf-menu {
            --bf-wc-nav-height: var(--bf-wc-navigation-height);
        }

        .bf-wc-navigation-wrapper {
            top: 0px;
            width: 100%;
            background-color: #fff;
            display: grid;
            grid-template-columns:  minmax(0, 1fr) minmax(0, 1200px) minmax(0, 1fr);
            grid-template-rows: 4em auto;
            grid-template-areas:
                '. nav-main .'
                'nav-sub nav-sub nav-sub';
            transition: top .5s ease-in-out;
        }

        .bf-wc-navigation-wrapper.navigation-up {
            top:-4em;
        }

        .bf-wc-navigation-wrapper.wide {
            display: block;
        }

        .bf-wc-navigation-inner {
            max-width: 1200px;
            height: 4.5625em;
            display: grid;
            align-items: center;
            grid-area: nav-main;
            grid-template-columns: 20em auto 20em;
            grid-template-rows: 4.5625em auto;
            grid-template-areas: 'logo . menu';
        }

        .bf-wc-navigation-wrapper.wide > .bf-wc-navigation-inner {
            max-width: none;
        }

        .sites-menu {
            /* the bygg-navigation controls how the menu toggler looks and behaves */
            grid-area: menu;
            align-self: stretch;
        }

        .bf-wc-navigation-sub-nav {
            grid-area: nav-sub;
            width: 100%;
        }
        .bf-wc-navigation-sub-nav.navigation-up {
            top: 0px;
        }

        .bf-wc-navigation-logo-large {
            margin: 0 2em;
            grid-area: logo;
        }

        .bf-wc-navigation-logo-small {
            grid-area: logo;
            display: none;
            margin: .75em;
        }

        [hidden] {
            display: none;
        }

        /* Mobile screens */
        /* Tablets */
        @media screen and (max-width: 768px) {
            .bf-wc-navigation-wrapper {
                min-width: 100%;
                grid-template-columns: auto;
                grid-template-areas: "nav-main";
            }

            .bf-wc-navigation-inner {
                max-width: 768px;
                grid-template-columns: 50% 50%;
                grid-template-areas: "logo menu";
            }

            .bf-wc-navigation-menu-dialog,
            .bf-wc-navigation-menu-dialog.wide {
                min-width: 100%;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 4em auto;
                grid-template-areas:
                '. .'
                'items items';
            }

            .bf-wc-navigation-menu-items {
                min-width: calc(100% - 2em);
                padding: 1em;
            }

            .bf-wc-navigation-menu-item {
                font-size: 1.375em;
                font-weight: 700;
                line-height: 1.75em;
                letter-spacing: .2px;

                margin: 0 0 .3em;
            }
        }

        /* Phones */
        @media screen and (max-width: 480px) {
            .bf-wc-navigation-logo-large {
                display: none;
            }

            .bf-wc-navigation-logo-small {
                display: block;
            }
        }

    </style>

    <div class="bf-wc-navigation-wrapper">
        <section class="bf-wc-navigation-inner">
            <a href="https://byggforetagen.se/" class="bf-wc-navigation-logo-large" >
                <svg width="208" height="24" viewBox="0 0 208 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M128.392 12.8637H122.255V16.5792H128.392V12.8637Z" fill="#4E5C62"/>
                    <path d="M188.184 12.8637H182.047V16.5792H188.184V12.8637Z" fill="#4E5C62"/>
                    <path d="M138.347 9.46333H134.222V23.6016H138.347V9.46333Z" fill="#4E5C62"/>
                    <path d="M112.954 15.7551C114.801 14.7717 115.681 12.7737 115.681 10.9904C115.681 7.72504 113.331 5.24226 109.674 5.24226H106.67V8.78463H108.888C110.585 8.78463 111.5 9.61915 111.5 10.9592C111.5 12.2197 110.585 13.1269 108.888 13.1269H106.67V16.5896H107.931C108.238 16.5896 108.559 16.5896 108.884 16.5896L111.679 23.6016H116.174L112.821 15.807C112.873 15.7897 112.915 15.7759 112.954 15.7551Z" fill="#4E5C62"/>
                    <path d="M86.624 3.95759C87.4253 3.95759 88.1477 3.47885 88.454 2.74477C88.7604 2.01068 88.5903 1.16593 88.0231 0.604689C87.456 0.0434433 86.6036 -0.123678 85.8637 0.181305C85.1237 0.486288 84.6421 1.20326 84.6435 1.99769C84.6454 3.08067 85.5316 3.95759 86.624 3.95759Z" fill="#4E5C62"/>
                    <path d="M94.5424 3.96105C95.6362 3.96105 96.5229 3.08202 96.5229 1.99769C96.5229 0.91335 95.6362 0.0343208 94.5424 0.0343208C93.4486 0.0343208 92.562 0.91335 92.562 1.99769C92.562 3.08202 93.4486 3.96105 94.5424 3.96105Z" fill="#4E5C62"/>
                    <path d="M181.673 19.7199V16.5792V12.8637V9.09282H182.047H189.277V5.2388H177.548V23.6016H189.305V19.7199H182.047H181.673Z" fill="#4E5C62"/>
                    <path d="M203.793 5.2388V16.3992L196.839 5.2388H191.953L203.559 23.6016H207.919V5.2388H203.793Z" fill="#4E5C62"/>
                    <path d="M134.222 9.09282L138.347 9.09628H139.066H143.279V5.2388H129.671V9.09282H134.222Z" fill="#4E5C62"/>
                    <path d="M129.3 9.09282V5.2388H129.052H117.756V23.6016H129.513V19.7199H121.881V16.5792V12.8637V9.09282H129.052H129.3Z" fill="#4E5C62"/>
                    <path d="M106.3 13.1269V8.78463V5.24226H102.171V23.6051H106.3V18.4145V16.6069V13.1269Z" fill="#4E5C62"/>
                    <path d="M191.827 5.73397V23.6016H195.927V12.2231L191.827 5.73397Z" fill="#4E5C62"/>
                    <path d="M148.724 9.97927L148.728 9.97581L150.858 16.226L153.38 23.6016H157.872L150.97 5.2388H147.107L148.724 9.97927Z" fill="#4E5C62"/>
                    <path d="M145.266 21.2816H152.193L150.921 17.5592H146.534L145.266 21.2816Z" fill="#4E5C62"/>
                    <path d="M22.7528 16.7247L29.2216 5.24226H24.625L20.7863 12.881L22.7528 16.7247Z" fill="#4E5C62"/>
                    <path d="M16.5773 5.24226H11.9562L18.4844 16.9671V23.6016H22.613V17.2649L16.5773 5.24226Z" fill="#4E5C62"/>
                    <path d="M46.3054 13.1927H36.8117V16.7143H42.0511H46.1797C46.3757 15.5508 46.418 14.367 46.3054 13.1927ZM37.4684 20.1632C34.4819 20.1181 32.0404 18.1721 32.0404 14.4393C32.0404 10.3706 34.8347 8.7223 37.3671 8.7223C39.7422 8.7223 40.9717 9.92041 41.5585 10.98L45.4566 9.44601C44.3214 7.17793 41.7821 4.90984 37.3775 4.90984C32.1382 4.90984 27.8419 8.85042 27.8419 14.4393C27.8419 20.0281 32.0404 23.9271 37.4684 23.9271C42.4492 23.9271 45.282 21.0219 46.1098 17.0744H41.9952C41.6005 18.9131 39.8365 20.2012 37.4684 20.1562V20.1632Z" fill="#4E5C62"/>
                    <path d="M66.3826 13.1927H56.8854V16.7143H62.1248H66.2499C66.4483 15.5511 66.493 14.3673 66.3826 13.1927ZM57.5456 20.1632C54.5591 20.1077 52.1176 18.179 52.1176 14.4462C52.1176 10.3775 54.9119 8.72923 57.4443 8.72923C59.8194 8.72923 61.0455 9.92733 61.6358 10.9869L65.5338 9.45294C64.3952 7.18485 61.8558 4.91676 57.4548 4.91676C52.2154 4.91676 47.9191 8.85735 47.9191 14.4462C47.9191 20.035 52.1176 23.9271 57.5456 23.9271C62.5264 23.9271 65.3592 21.0219 66.187 17.0744H62.0724C61.6777 18.9131 59.9138 20.2012 57.5456 20.1562V20.1632Z" fill="#4E5C62"/>
                    <path d="M175.539 13.1927H166.538V16.7143H171.289H175.414C175.61 15.5508 175.652 14.367 175.539 13.1927ZM166.702 20.1632C163.716 20.1077 161.274 18.179 161.274 14.4462C161.274 10.3775 164.069 8.72923 166.598 8.72923C168.976 8.72923 170.202 9.92733 170.789 10.9869L174.687 9.45294C173.548 7.18485 171.009 4.91676 166.605 4.91676C161.386 4.91676 157.072 8.85735 157.072 14.4462C157.072 20.035 161.274 23.9271 166.702 23.9271C171.683 23.9271 174.516 21.0219 175.344 17.0744H171.226C170.834 18.9131 169.057 20.2012 166.702 20.1562V20.1632Z" fill="#4E5C62"/>
                    <path d="M68.7054 5.2388L72.8515 9.12052L72.848 9.12398H80.3717V5.2388H68.7054Z" fill="#4E5C62"/>
                    <path d="M72.5895 16.7385V13.0092V9.38022L68.433 5.49158V23.6016H72.5895V16.7385Z" fill="#4E5C62"/>
                    <path d="M79.0968 13.0092H72.9598V16.7385H79.0968V13.0092Z" fill="#4E5C62"/>
                    <path d="M90.6094 19.9277C88.0247 19.9277 85.2548 18.2171 85.2548 14.3873C85.2548 10.651 87.885 8.91275 90.4103 8.82619V4.83366C85.2758 4.93062 80.9795 8.65997 80.9795 14.4116C80.9795 20.1632 85.3945 23.9687 90.6164 23.9687C93.2183 23.9952 95.7212 22.9817 97.5603 21.157L94.511 18.3037C93.491 19.3538 92.0799 19.9412 90.6094 19.9277ZM90.7841 4.83366V8.82619C93.292 8.90237 95.9396 10.6407 95.9396 14.3873C95.9859 15.7046 95.5671 16.9965 94.7555 18.0405L97.8013 20.9007C99.3973 19.1142 100.257 16.7975 100.208 14.4116C100.222 8.64959 95.9047 4.91677 90.7841 4.83366Z" fill="#4E5C62"/>
                    <path d="M4.02033 15.9594V12.5971V8.88159L0 5.49158V23.3696L4.02033 19.9069V15.9594Z" fill="#4E5C62"/>
                    <path d="M9.98271 14.0965C11.4951 13.6048 12.4277 11.8872 12.4277 10.1593C12.4347 7.52766 10.5835 5.22841 6.92293 5.22841H0.293396L4.31373 8.61842H6.00429C7.62849 8.61842 8.38296 9.39407 8.38296 10.5887C8.38296 11.7834 7.49576 12.5867 6.05669 12.5867H4.39406V15.9629H6.60856C8.22927 15.9629 9.17235 16.8459 9.17235 18.1167C9.17235 19.3875 8.20482 20.267 6.60856 20.267H4.19148L0.317846 23.6016H7.4748C10.9677 23.6016 13.273 21.4028 13.273 18.4076C13.3396 16.3809 11.9681 14.5839 9.98271 14.0965Z" fill="#4E5C62"/>
                </svg>
            </a>
            <a href="https://byggforetagen.se/" class="bf-wc-navigation-logo-small" >
                <svg width="34" height="48" viewBox="0 0 34 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.1126 28.0392V19.2453V9.52755L0 0.661133V47.4204L10.1126 38.3638V28.0392Z" fill="#4E5C62"/>
                    <path d="M25.1101 23.1668C28.9144 21.8808 31.2602 17.3887 31.2602 12.8694C31.2778 5.98642 26.6213 0 17.4137 0H0.738037L10.8506 8.86642H15.103C19.1884 8.86642 21.0862 10.8679 21.0862 14.0196C21.0862 17.1713 18.8545 19.2453 15.2348 19.2453H11.0527V28.0755H16.6229C20.6996 28.0755 23.0718 30.3849 23.0718 33.7087C23.0718 37.0325 20.6381 39.3328 16.6229 39.3328H10.5431L0.799538 48H18.8018C27.5877 48 33.3864 42.2491 33.3864 34.4151C33.5379 29.1257 30.0922 24.4427 25.1101 23.1668Z" fill="#4E5C62"/>
                </svg>
            </a>
            <bf-menu class="sites-menu" menu-items=[] menu-header-text="" menu-placement="left" menu-width="500px"></bf-menu>
        </section>
        <section class="bf-wc-navigation-sub-nav">
            <slot id="sub-nav" name="sub-nav"></slot>
        </section>
    </div>
`;

/**
 * Header bar to provide navigation to all Byggföretagen websites.
 *
 * @element bf-navigation
 *
 * @attr {String} [current-site] - Site name corresponding to the label in the menu, used to display default site name in sites menu
 * @attr {Boolean} [wide-navigation] - Makes the components width 100% of the view port
 * @attr {Boolean} [hide-menu] - Hides the web site menu
 * @attr {Boolean} [prevent-fold-header] - Prevents the header from folding up/down
 *
 * @slot sub-nav - Slot a sub navigation to make it sticky. Otherwise, leave it outside.
 */
export default class BfNavigation extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({
      mode: 'open',
      delegatesFocus: true,
    });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.navWrapper = this.shadow.querySelector('.bf-wc-navigation-wrapper');
    this.sitesMenu = this.shadow.querySelector('.sites-menu');
    this.slottedSubNavigation = this.shadow.querySelector('#sub-nav');
    this.fontSize = window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size').split('px')[0];
    this.scrollBefore = this.fontSize * 4;
    this.preventFoldHeader = false;

    this.menuItemsOptions = [
        {label: 'Företagarservice', path: 'https://byggforetagen.se/foretagarservice'},
        {label: 'Arbetsmiljö och säkerhet', path: 'https://byggforetagen.se/foretagarservice/amnesomrade/arbetsmiljo-och-sakerhet', secondary: true},
        {label: 'Arbetsrätt och Kollektivavtal', path: 'https://medlem.arbetsgivarguiden.se', secondary: true},
        {label: 'Entreprenadrätt och Standardavtal', path: 'https://entreprenadratt.byggforetagen.se', secondary: true},
        {label: 'Klimat och miljö', path: 'https://byggforetagen.se/foretagarservice/amnesomrade/klimat-och-miljo', secondary: true},
        {label: 'Skatter', path: 'https://byggforetagen.se/foretagarservice/amnesomrade/skatter', secondary: true},
        {label: 'Sund konkurrens', path: 'https://byggforetagen.se/foretagarservice/amnesomrade/sund-konkurrens', secondary: true},
        {label: 'Affärstjänster', path: ''},
        {label: 'Entreprenadindex', path: 'https://entreprenadindex.se'},
        {label: 'Förlag', path: 'https://forlag.byggforetagen.se'},
        {label: 'KMA', path: 'https://bkma.byggforetagen.se'},
        {label: 'Säkerhetspark', path: 'https://sakerhetspark.se'},
        {label: 'Utbildningscenter', path: 'https://buc.se'},
      ];
  }

  connectedCallback() {
    // Since NextJs don't have access to the document object we do a safety check
    if(typeof document !== 'undefined'){
      // eslint-disable-next-line no-restricted-globals
      addEventListener('bf-menu-toggle', event => this.#handleScrollBar(event.detail.open));
    }

    this.sitesMenu.setAttribute('menu-items', JSON.stringify(this.menuItemsOptions));
  }

  static observedAttributes = ['current-site', 'wide-navigation', 'hide-menu'];

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this.navWrapper) return;
    switch (name) {
      case 'current-site': {
        this.sitesMenu.setAttribute('menu-header-text', newValue);
        break;
      }

      case 'wide-navigation': {
        this.#handleWideNavigation();
        break;
      }
      case 'hide-menu': {
        this.#handleHideMenu();
        break;
      }
      case 'prevent-fold-header': {
        if(newValue === 'true') {
            this.removeEventListener('scroll', this.#scollHeader);
        } else {
            this.addEventListener('scroll', this.#scollHeader);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  #handleWideNavigation() {
    this.navWrapper.classList.add('wide');
    this.sitesMenu.classList.add('wide');
  }

  #handleHideMenu() {
    this.sitesMenu.setAttribute('hidden', true);
  }

  #scollHeader() {
    /*
    ** TODO: when scroll driven animations are widely supported,
    ** take a look at https://developer.chrome.com/articles/scroll-driven-animations
    */
    const scrolled = Math.ceil(window.scrollY);
    if(scrolled > this.scrollBefore){
        this.navWrapper.classList.add('navigation-up');
    } else if(scrolled < this.scrollBefore){
        this.navWrapper.classList.remove('navigation-up');
    }

    if(scrolled > 72) this.scrollBefore = scrolled;
}

  #handleScrollBar(menuOpen) {
    if(
        document.documentElement.scrollHeight > document.documentElement.clientHeight ||
        !menuOpen
    ) {
        this.slottedSubNavigation.assignedElements().forEach(element => {
            element.style = `overflow: ${menuOpen ? 'hidden' : 'initial'};`;
            element.style = `overflow-y: ${menuOpen ? 'scroll' : 'none'};`;
        });
    }
}
}

if (!customElements.get('bf-navigation'))
  customElements.define('bf-navigation', BfNavigation);
